import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero'

const Mail = require('../svgs/mail.svg')
const Mic = require('../svgs/mic.svg')
const Twitter = require('../svgs/twitter.svg')

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "contact.jpg" }) {
      ...heroImage
    }
  }
`

const ContactPage = () => (
  <Layout>
    <SEO
      title="Contact Us | amillionmonkeys"
      descriptions="If you're interested in working with amillionmonkeys on your next project, we'd love to hear from you."
    />
    <StaticQuery
      query={query}
      render={data => (
        <React.Fragment>
          <Hero image={data.hero.childImageSharp.fluid}>
            <div className="max-w-md m-auto ">
              <p className="text-3xl text-white text-center  font-serif text-shadow">
                amillionmonkeys is based in Brighton. We travel to London each week and work with clients throughout the
                UK, and the odd one elsewhere in Europe.
              </p>
            </div>
          </Hero>
          <div className="bg-yellow text-white py-16  pb-24 ">
            <div className="container text-2xl md:flex">
              <div className="px-2 flex-1 font-serif pb-8">
                <h1 className="text-5xl font-impact">Get in touch</h1>
                <p>
                  If you're interested in working with amillionmonkeys on your next project, we'd love to hear from you.
                </p>
                <ul className="list-reset">
                  <li className="my-2 flex items-center ">
                    <Mic className="mr-2" />
                    07512 944360
                  </li>
                  <li className="my-2 ">
                    <a className="text-white flex items-center " href="mailto:hello@amillionmonkeys.co.uk">
                      <Mail className="mr-2" /> hello@amillionmonkeys.co.uk
                    </a>
                  </li>
                  <li className="my-2 flex items-center ">
                    <Twitter className="mr-2" />
                    @monkeyphil
                  </li>
                </ul>
              </div>
              <div className="px-2 flex-1 font-serif leading-tight">
                <h2 className="text-5xl font-impact">Send us a message</h2>
                <form className="py-2" name="contact" action="/success" method="POST" data-netlify="true">
                  <input type="hidden" name="form-name" value="contact" />

                  <input
                    required
                    type="text"
                    name="name"
                    placeholder="Your name"
                    className="focus:outline-none focus:shadow-outline appearance-none mb-4 bg-white p-2 pb w-full "
                  />
                  <input
                    required
                    type="text"
                    name="email"
                    placeholder="Your email"
                    className="focus:outline-none focus:shadow-outline appearance-none mb-4 bg-white p-2 pb w-full "
                  />
                  <input
                    type="text"
                    name="phone"
                    placeholder="Your phone"
                    className="focus:outline-none focus:shadow-outline appearance-none mb-4 bg-white p-2 pb w-full "
                  />
                  <textarea
                    required
                    name="message"
                    placeholder="Your message"
                    className="focus:outline-none focus:shadow-outline appearance-none mb-4 bg-white p-2 pb w-full "
                    rows="4"
                  />
                  <button className="font-impact  text-3xl text-white border-white border-2 p-3 text-lg inline-block">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    />
  </Layout>
)

export default ContactPage
